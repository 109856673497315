import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configure-patron-account-options"
    }}>{`Configure Patron Account Options`}</h1>
    <hr></hr>
    <p>{`To configure your mobile app or template's account options, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Select the Mobile App or Template you wish to edit. `}</li>
      <li parentName="ol">{`Click on 'Edit' next to the Data Provider. This action will load the Edit Data Provider page. Navigate to the Features, Display and Account sections. `}</li>
    </ol>
    <p><img alt="CMS Patron Account Options" src={require("./images/patron_account_options.png")} />{` `}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Enable 'Card Scan' to enable your app to allow for library barcode scanning. `}</li>
      <li parentName="ol">{`Set 'User Barcode Symbology' to determine which format to use to decode the library card barcode. `}</li>
      <li parentName="ol">{`Enable 'Formats' to enable your app to display the item format in the search results.`}</li>
      <li parentName="ol">{`Enable 'Thumbnails' to enable your app to display book jacket/cover art in the search results. `}</li>
      <li parentName="ol">{`Enable 'MARC Record' to enable your app to display the MARC record in the item details view. `}</li>
      <li parentName="ol">{`Enable 'Charges/Fines' to allow patrons to view any fines against their account. `}</li>
      <li parentName="ol">{`Enable 'View Reservations/Holds' to allow patrons to view the items that they currently have on hold. `}</li>
      <li parentName="ol">{`Enable 'Place Reservations/Holds' to allow patrons to place a hold on items through your app. `}</li>
      <li parentName="ol">{`Enable 'Suspend Reservations/Holds' to allow patrons to temporarily remove their hold request and resume it at a later date.`}</li>
      <li parentName="ol">{`If you want your app to conceal the 'Suspend' button within the holds display for certain hold statuses, enter in the specific hold status in the 'Deny Hold Suspend' field. `}</li>
      <li parentName="ol">{`If you want your app to conceal the 'Cancel' button within the holds display for certain hold statutes, enter in the specific hold status in the 'Deny Hold Cancel' field. `}</li>
      <li parentName="ol">{`Enable 'Cancel Reservations/Holds' to allow patrons to remove their hold request. `}</li>
      <li parentName="ol">{`Enable 'Change Reservations/Holds' to allow patrons to change the status of their hold request. `}</li>
      <li parentName="ol">{`Enable 'Show Hold Position' to display the current hold position to the patron within your app.`}</li>
      <li parentName="ol">{`Enable 'Show Checkout History' to allow patrons to view the list of items they have previously checked out. Set the 'History Sort Order' to either display the oldest first or the newest first.  `}</li>
      <li parentName="ol">{`To charge a fee associated with placing an item on hold, enter in an ammount in the 'Reserveation / Hold Charge/ field. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      